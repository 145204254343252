import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { IStaffTracing } from '@/models/tracing-staff/interfaces/StaffTracing'
import { IStaffTracingResponse } from '@/models/tracing-staff/interfaces/staffTracingResponse'
import { Api } from './api'

class StaffTracingApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  parseStaffTracingResponse(
    tracing: IStaffTracingResponse
  ): StaffTracingResponse {
    return new StaffTracingResponse(tracing.page, tracing.nPages, tracing.data)
  }

  public async getStaffTracing(
    query: Record<string, string | boolean | number | Date | string[]>
  ): Promise<StaffTracingResponse> {
    const response = await Api.get<StaffTracingResponse>(
      `${this.baseURL}/v1/tracing`,
      query
    )
    return this.parseStaffTracingResponse(response)
  }

  public async getTeacherActivity(id: string): Promise<StaffTracing[]> {
    const response = await Api.get<StaffTracing[]>(
      `${this.baseURL}/v1/tracing/activityRegister/${id}`
    )
    return response
  }

  public async updateTracing(tracing: StaffTracing): Promise<StaffTracing> {
    const response = await Api.put<StaffTracing>(
      `${this.baseURL}/v1/tracing/updateTracing`,
      tracing
    )
    return response
  }
}

export const StaffTracingAPI = new StaffTracingApi()
