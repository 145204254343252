
import { TracingState } from '@/models/components/tables/enums'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const myOptions = [
      {
        name: TracingState.ALL, id: -1
      },
      {
        name: TracingState.SEND, id: 0
      },
      {
        name: TracingState.SENT, id: 1
      }
    ]
    return {
      optionSelected: '',
      myOptions
    }
  }
})
