
import { Column } from '@/models/components/tables/Column'
import { ColumnFields, OnboardingStageEnum, TracingState } from '@/models/components/tables/enums'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { formatDate } from '@/utils/formatDate'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    staffTracing: {
      type: StaffTracingResponse,
      required: true
    },
    showCol: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'Tipo', field: ColumnFields.TracingType },
      { header: 'Rating', field: ColumnFields.TracingRating },
      { header: 'Tags', field: ColumnFields.TracingTags },
      { header: 'Accion', field: ColumnFields.TracingActions }
    ]
    return {
      columns,
      ColumnFields,
      OnboardingStageEnum,
      TracingState,
      formatDate
    }
  }
})
