
import { ICoursePlan } from '@/models/academic-definitions/interfaces/CoursePlan'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    coursePlans: {
      type: Array as PropType<Array<ICoursePlan>>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multipleSelection: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: false
    }
  },
  emits: ['apply-filter'],
  setup (props, { emit }) {
    const selectedCoursePlan = ref<ICoursePlan>()
    const selectedCoursePlans = ref<ICoursePlan[]>()
    const clickButton = () => {
      emit('apply-filter', 0)
    }
    return {
      selectedCoursePlan,
      selectedCoursePlans,
      clickButton
    }
  }
})
