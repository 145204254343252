
import { TracingStage, TracingState } from '@/models/components/tables/enums'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const myOptions = [
      {
        name: TracingStage.ALL, id: -1
      },
      {
        name: TracingStage.START, id: 1
      },
      {
        name: TracingStage.MID, id: 1
      },
      {
        name: TracingStage.FINAL, id: 2
      }
    ]
    return {
      optionSelected: '',
      myOptions
    }
  }
})
