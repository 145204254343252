import { StaffTracing } from './StaffTracing'

export class StaffTracingResponse {
    page: number
    nPages: number
    data: StaffTracing[]
    constructor (
      page: number,
      nPages: number,
      data: StaffTracing[]
    ) {
      this.page = page
      this.nPages = nPages
      this.data = data.map(tracing => {
        if (tracing.kickOffDate) {
          tracing.kickOffDate = new Date(tracing.kickOffDate).toLocaleDateString()
        }
        return tracing
      })
    }
}
