
import { ICoursePlan } from '@/models/academic-definitions/interfaces/CoursePlan'
import { defineComponent, onBeforeMount, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import TracingStateSelector from '@/views/education/staff/components/TracingStateSelector.vue'
import TracingStageSelector from '@/views/education/staff/components/TracingStageSelector.vue'
import TracingTable from '@/views/education/staff/components/TracingTable.vue'
import TracingReinfoTable from '@/views/education/staff/components/TracingReinfoTable.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { Observable } from 'rxjs'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { StaffTracingAPI } from '@/api/staffTracing.api'
import router from '@/router'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { CommonRouteNames } from '@/router/route-names'
import CoursePlanSelectorCustom from '@/views/education/staff/components/CoursePlanSelectorCustom.vue'
import OverlayPanel from 'primevue/overlaypanel'
import ScrollPanel from 'primevue/scrollpanel'
import { Column } from '@/models/components/tables/Column'
import { ColumnFields } from '@/models/components/tables/enums'
import { CustomFilter, FilterProps, FilterValue } from '@/models/custom-filters/classes/CustomFilter'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { CustomFiltersAPI } from '@/api/custom-filters.api'
import { ToastSeverities, ToastSummaries, ToastLife } from '@/models/components/toast/enums'
import { useToast } from 'primevue/usetoast'
import Chip from 'primevue/chip'

export default defineComponent({
  components: {
    CoursePlanSelectorCustom,
    TracingTable,
    TracingStateSelector,
    TracingStageSelector,
    TracingReinfoTable,
    OverlayPanel,
    ScrollPanel,
    Chip
  },
  props: {
    allCoursePlans: {
      type: Array as PropType<Array<ICoursePlan>>,
      required: true
    }
  },
  setup () {
    const nameFilter = ref<string>('')
    const tracingStageFilter = ref<string>('Todos')
    const tracingstateFilter = ref<string>('Todos')
    const coursesFilter = ref<CoursePlan[]>([])
    const staffTracing = ref<StaffTracingResponse>(new StaffTracingResponse(0, 0, []))
    const isLoading = ref(false)
    const errorRef = ref<null|unknown>(null)
    const ratinglt = ref<boolean>(false)
    const scrollComponent = ref()
    const currentPage = ref(0)
    const showDialog = ref(false)
    const filterNameInput = ref<string>('')
    const user = useUserSessionStore().currentUser
    const toast = useToast()
    const allCustomViews = ref<CustomFilter[]>([])
    const viewSelected = ref<CustomFilter|null>(null)
    const searchTxt$ = new Observable<string>(subscriber => {
      watch(nameFilter, () => subscriber.next(nameFilter.value))
    })
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'Tipo', field: ColumnFields.TracingType },
      { header: 'Etapa', field: ColumnFields.TracingStage },
      { header: 'Rating', field: ColumnFields.TracingRating },
      { header: 'Estado', field: ColumnFields.TracingState }
    ]

    const columns2:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'Tipo', field: ColumnFields.TracingType },
      { header: 'Rating', field: ColumnFields.TracingRating }
    ]
    const showCol = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'Tipo', 'Etapa', 'Rating', 'Estado'])
    const defaultCol = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'Tipo', 'Etapa', 'Rating', 'Estado'])
    const showCol2 = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'Tipo', 'Rating'])
    const defaultCol2 = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'Tipo', 'Rating'])
    const op = ref()

    const removeSelectedView = () => {
      viewSelected.value = null
      showCol.value = defaultCol.value
      showCol2.value = defaultCol2.value
    }

    const selectView = (value: CustomFilter) => {
      viewSelected.value = value
      if (!ratinglt.value) {
        showCol.value = viewSelected.value.filterProps.columns.value as string[]
      } else {
        showCol2.value = viewSelected.value.filterProps.columns.value as string[]
      }
    }

    const showDialogOpen = () => {
      showDialog.value = true
    }

    const showDialogClose = () => {
      showDialog.value = false
    }

    const getFilterView = async () => {
      try {
        const cf = await CustomFiltersAPI.findAllByTableName(user._id, !ratinglt.value ? TableName.TeachersRegularView : TableName.TeacherReinfoView)
        allCustomViews.value = cf.find(type => type._id === TypeFilter.View)?.data as CustomFilter[] || []
      } catch (e) {
        console.error(e)
      }
    }

    const deleteFilter = async (cf: CustomFilter): Promise<void> => {
      try {
        await CustomFiltersAPI.delete(cf._id as string)
        toast.add({
          severity: ToastSeverities.Success,
          summary: ToastSummaries.Success,
          detail: 'Filtro eliminado',
          life: ToastLife.Default
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: 'Error al eliminar filtro',
          life: ToastLife.Default
        })
      } finally {
        await getFilterView()
        removeSelectedView()
      }
    }

    const saveFilterView = async () => {
      const isFilterType = TypeFilter.View
      const filterProps: Record<string, FilterValue<unknown>> = {
        columns: {
          value: !ratinglt.value ? showCol.value : showCol2.value
        }
      }
      const customFilter = new CustomFilter(
        filterProps,
        user._id,
        isFilterType,
        !ratinglt.value ? TableName.TeachersRegularView : TableName.TeacherReinfoView,
        filterNameInput.value,
        false // si hay un filtro guardado preseleccionado, lo que va a hacer es sobreescribirlo
      )
      try {
        await CustomFiltersAPI.upsert(customFilter)
        showDialogClose()
        toast.add({
          severity: ToastSeverities.Success,
          summary: ToastSummaries.Success,
          detail: 'Vista guardada',
          life: ToastLife.Default
        })
      } catch (error) {
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: isFilterType ? 'Error al crear filtro' : 'Error al crear la vista',
          life: ToastLife.Default
        })
      } finally {
        getFilterView()
      }
    }

    const toggle = (event: Event) => {
      op.value.toggle(event)
    }

    const clickCheck2 = (selectedCheck: any) => {
      if (showCol2.value.find(element => element === selectedCheck.key)) {
        showCol2.value = []
      }
      showCol2.value.push(selectedCheck)
    }

    const clickCheck = (selectedCheck: any) => {
      if (showCol.value.find(element => element === selectedCheck.key)) {
        showCol.value = []
      }
      showCol.value.push(selectedCheck)
    }
    const loadTracing = async (page: number) => {
      try {
        isLoading.value = true
        // staffTracing.value = new StaffTracingResponse(0, 0, [])
        const response: StaffTracingResponse = await StaffTracingAPI.getStaffTracing({
          limit: 20,
          page: page + 1,
          role: 'teacher',
          name: nameFilter.value,
          courses: coursesFilter.value.map((course) => course.information.name),
          tracingStage: tracingStageFilter.value === 'Todos' ? '' : tracingStageFilter.value,
          tracingState: tracingstateFilter.value === 'Todos' ? '' : tracingstateFilter.value,
          ...(ratinglt.value && { ratinglesst: true })
        })
        response.data.forEach(element => {
          staffTracing.value.data.push(element)
        })
        currentPage.value = currentPage.value + 1
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    }
    const reset = () => {
      currentPage.value = 0
      staffTracing.value.data = []
      allCustomViews.value = []
      viewSelected.value = null
      showCol.value = defaultCol.value
      showCol2.value = defaultCol2.value
    }

    const onScroll = (event: any) => {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        loadTracing(currentPage.value)
      }
    }
    const tabChange = async (event: any) => {
      if (event.index === 1) ratinglt.value = true
      else ratinglt.value = false
      reset()
      loadTracing(0)
      getFilterView()
    }
    const applyFilter = () => {
      reset()
      loadTracing(currentPage.value)
    }
    const onPage = async (event: any) => {
      // loadTracing(event.page)
    }
    onBeforeMount(() => {
      isLoading.value = true
    })
    onMounted(async () => {
      loadTracing(currentPage.value)
      getFilterView()
    })
    watch(nameFilter, () => {
      searchTxt$.pipe(
        debounceTime(800),
        distinctUntilChanged()
      ).subscribe(() => {
        applyFilter()
      })
    })
    watch(tracingStageFilter, () => {
      applyFilter()
    })
    watch(tracingstateFilter, () => {
      applyFilter()
    })
    return {
      scrollComponent,
      tracingStageFilter,
      nameFilter,
      coursesFilter,
      tracingstateFilter,
      staffTracing,
      isLoading,
      ratinglt,
      loadTracing,
      onPage,
      tabChange,
      onScroll,
      applyFilter,
      toggle,
      op,
      columns,
      clickCheck,
      showCol,
      columns2,
      showCol2,
      clickCheck2,
      showDialog,
      filterNameInput,
      showDialogClose,
      showDialogOpen,
      saveFilterView,
      allCustomViews,
      deleteFilter,
      removeSelectedView,
      selectView,
      viewSelected
    }
  }
})
